<template>
	<div class="pt-2 px-6 pb-6 overflow-hidden">
		<div v-if="department && transactionEdit">
			
			<form class="mb-6">
				<div>
					<strong>
						Tidspunkt
						<a 
							v-if="!showTimestampInput"
							class="link font-normal ml-2"
							@click="showTimestampInput = !showTimestampInput">
							
							Endre
						</a>
					</strong>
					<div class="my-2">
						{{ transactionEdit.createdAt  | moment('lll') }}
					</div>
				</div>
				
				<div  
					v-if="showTimestampInput"
					class="">
					
					<input
						type="datetime-local"
						class="w-full border p-2"
						:value="$moment(transactionEdit.createdAt).format('YYYY-MM-DDThh:mm')"
						@input="event => transactionEdit.createdAt = $moment(event.target.value).valueOf()"
					/>
				</div>
			</form>
			<form v-if="entity == 'products'">
				
				<div class="flex mb-4 text-black border-b border-beige pb-4">
					<div class="flex-grow">
						Produkt
					</div>
					<div class="flex-none w-20 text-left">
						Antall
					</div>
					<div class="flex-none w-24 text-center">
						Á kr
					</div>
				</div>
				
				<div v-if="department.products">
					<div 
						v-for="product in departmentProductsSorted"
						:key="'product_'+product.productId"
						class="flex border-b border-beige items-start mb-4">
						
						<div 
							@click="onToggleProduct( product )"
							class="flex-none cursor-default mt-3">
							
							<input 
								type="checkbox" 
								:checked="transactionEdit.products.find( t => t.productId == product.productId)"
								disabled
								class="pointer-events-none"
								name="checkbox"
							/>
						</div>
						
						<div 
							@click="transactionEdit.products.find( t => t.productId == product.productId) ? null : onToggleProduct( product )"
							class="flex-grow">
							
							<div 
								@click="transactionEdit.products.find( t => t.productId == product.productId) ? null : onToggleProduct( product )"
								class="flex-grow flex items-center gap-4">
								
								<div 
									class="flex-grow mb-3"
									:class="transactionEdit.products.find( t => t.productId == product.productId) ? 'opacity-100' : 'opacity-75 cursor-pointer'">
									
									{{product.name}}
								</div>
								
								<div class="flex-none w-24">
									<div 
										v-if="transactionEdit.products.find( t => t.productId == product.productId)"
										class="flex items-start justify-center mx-2 space-x-1">
										
										<i 
											@click="transactionEdit.products.find( t => t.productId == product.productId).qty--"
											class="flex-none fas fa-minus text-gray-dark w-6 h-12 flex items-center justify-center mb-3"
											:class="transactionEdit.products.find( t => t.productId == product.productId).qty > 1 ? '' : 'opacity-25 pointer-events-none'"
										/>
										
										<input 
											type="number" 
											v-model="transactionEdit.products.find( t => t.productId == product.productId).qty"
											class="text-center flex-grow"
											style="padding-left: 0; padding-right: 0; min-width: 3rem;"
											name="qty"
										/>
										
										<i 
											@click="transactionEdit.products.find( t => t.productId == product.productId).qty++"
											class="flex-none fas fa-plus text-gray-dark w-6 h-12 flex items-center justify-center mb-3"
										/>
									</div>
									<input 
										v-else
										type="number" 
										value="1"
										class="text-center pointer-events-none opacity-50"
									/>
								</div>
								
								<div class="flex-none w-20">
									<input 
										v-if="transactionEdit.products.find( t => t.productId == product.productId)"
										type="number" 
										v-model="transactionEdit.products.find( t => t.productId == product.productId).value"
										class="text-right"
										name="value"
									/>
									<input 
										v-else
										type="number" 
										v-model="product.value"
										class="text-right pointer-events-none opacity-50"
									/>
								</div>
							</div>
							<div v-if="transactionEdit.products.find( t => t.productId == product.productId)">
								<input 
									type="text" 
									v-model="transactionEdit.products.find( t => t.productId == product.productId).comment"
									class="flex-grow"
									name="comment"
									placeholder="Kommentar"
								/>
							</div>
						</div>
					</div>
				</div>
				<div v-else>
					Fant ingen produkter
				</div>
			</form>
			<form v-else-if="entity == 'tasks'">
				<div class="flex mb-4 text-black border-b border-beige pb-4">
					<div class="flex-grow">
						Oppgave
					</div>
					<div class="flex-none w-20 text-left">
						Antall
					</div>
				</div>
				
				<div v-if="department.tasks">
					<div 
						v-for="task in departmentTasksSorted"
						:key="'task_'+task.taskId"
						class="flex border-b border-beige items-start mb-4">
						
						<div 
							@click="onToggleTask( task )"
							class="flex-none cursor-default mt-3">
							
							<input 
								type="checkbox" 
								:checked="transactionEdit.tasks.find( t => t.taskId == task.taskId)"
								disabled
								class="pointer-events-none"
								name="checkbox"
							/>
						</div>
						
						
						
						
						<div 
							@click="transactionEdit.tasks.find( t => t.taskId == task.taskId) ? null : onToggleTask( task )"
							class="flex-grow">
						
							<div 
								@click="transactionEdit.tasks.find( t => t.taskId == task.taskId) ? null : onToggleTask( task )"
								class="flex-grow flex items-center gap-4 ">
								
								<div 
									class="flex-grow mb-3"
									:class="transactionEdit.tasks.find( t => t.taskId == task.taskId) ? 'opacity-100' : 'opacity-75'">
									
									{{task.name}}
								</div>
								
								<div class="flex-none w-24 ">
									<div 
										v-if="transactionEdit.tasks.find( t => t.taskId == task.taskId)"
										class="flex items-start justify-center mx-2 space-x-1">
										
										<i 
											@click="transactionEdit.tasks.find( t => t.taskId == task.taskId).qty--"
											class="flex-none fas fa-minus text-gray-dark w-6 h-12 flex items-center justify-center mb-3"
											:class="transactionEdit.tasks.find( t => t.taskId == task.taskId).qty > 1 ? '' : 'opacity-25 pointer-events-none'"
										/>
										
										<input 
											type="number" 
											v-model="transactionEdit.tasks.find( t => t.taskId == task.taskId).qty"
											class="text-center flex-grow"
											style="padding-left: 0; padding-right: 0; min-width: 3rem;"
											name="qty"
										/>
										
										<i 
											@click="transactionEdit.tasks.find( t => t.taskId == task.taskId).qty++"
											class="flex-none fas fa-plus text-gray-dark w-6 h-12 flex items-center justify-center mb-3"
										/>
									</div>
									<input 
										v-else
										type="number" 
										value="1"
										class="text-center pointer-events-none opacity-50"
									/>
								</div>
							</div>
							<div v-if="transactionEdit.tasks.find( t => t.taskId == task.taskId)">
								<input 
									type="text" 
									v-model="transactionEdit.tasks.find( t => t.taskId == task.taskId).comment"
									class="flex-grow"
									name="comment"
									placeholder="Kommentar"
								/>
							</div>
						</div>
					</div>
				</div>
				<div v-else>
					Fant ingen oppgaver
				</div>
			</form>
		</div>
	</div>
</template>

<script>
export default {
	props: { 
		transaction: {
			type: Object,
			required: true,
		},
	},
	
	computed:{
		departments(){
			return this.$store.getters.getDepartments;
		},
		
		department(){
			return this.departments.find( d => d.departmentId == this.transaction.departmentId);
		},
		
		departmentProductsSorted(){
			if (!this.department.products) {
				return null;
			}
			
			const productsThatWasNotFoundInDepartmentToday = this.transactionEdit.products
				.filter( p => !this.department.products.find(dP => dP.productId == p.productId) );
			
			return [
				...productsThatWasNotFoundInDepartmentToday,
				...this.department.products,
			];
		},
				
		departmentTasksSorted(){
			if (!this.department.tasks) {
				return null;
			}
			
			const tasksThatWasNotFoundInDepartmentToday = this.transactionEdit.tasks
				.filter( t => !this.department.tasks.find(dT => dT.taskId == t.taskId) );
			
			return [
				...tasksThatWasNotFoundInDepartmentToday,
				...this.department.tasks,
			];
		},
		
		entity(){
			return this.transaction.products ? 'products' : 'tasks';
		},
	},
	
	data(){
		return {
			loading: null,
			transactionEdit: null,
			showTimestampInput: false,
		}
	},
	
	methods: {
		onToggleProduct( product ){
			const transaction = this.transactionEdit.products.find( t => t.productId == product.productId);
			
			if ( !transaction ) {
				this.transactionEdit.products.push( {
					...product,
					qty: 1,
					comment: null,
				});
			}
			else {
				this.transactionEdit.products = this.transactionEdit.products.filter( t => t.productId != product.productId);
			}
		},
		
		onToggleTask( task ){
			const transaction = this.transactionEdit.tasks.find( t => t.taskId == task.taskId);
			
			if ( !transaction ) {
				this.transactionEdit.tasks.push( {
					...task,
					qty: 1,
					comment: null,
				});
			}
			else {
				this.transactionEdit.tasks = this.transactionEdit.tasks.filter( t => t.taskId != task.taskId);
			}
		},
		
        createCopy(){
            this.transactionEdit = JSON.parse(
                JSON.stringify({
                    ...this.transaction,
                })
            );
        },
	},
	
	mounted(){
		this.createCopy();
	}
}
</script>

<style lang="scss" scoped>
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}
</style>
